<template>

    <div class="page exam-record">
        <div class="page-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>考试记录</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div>
            <mainSelect :showPopup="1"
                        :selectPaperNameByHome="paperName"
                        @new-select-submit-date="selectBySubmitDate"
                        @reset-select="resetSelectRecord"
                        @send-selected-to-parent="getTestPaperBySelect"
                        @new-select-paper-month="selectPaperByMonth2"
                        @new-select-context="updateSelectPaperName"
            >
            </mainSelect>
            <div v-loading="loading">
                <div v-if="examinationRecordArray.length === 0">
                    <el-empty description="考试烦恼抛诸脑后，学业记录清白如初。" style="background-color: #fff"></el-empty>
                </div>
                <div v-else>
                    <examDetails :examinationRecordArray="examinationRecordArray"></examDetails>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                                   :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
                                   layout="total, sizes, prev, pager, next, jumper" :total="allInfo.total"
                                   style="margin-top: 20px;text-align: center">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import mainSelect from "@/components/mainSelect.vue"
    import examDetails from "./components/examDetails.vue"

    import {
        examinationRecord
    } from "@/api/index.js"

    export default {
        components: {
            mainSelect,
            examDetails
        },
        watch: {
            paperName: {
                handler: function (n, o) {
                    if (n !== undefined) {
                        this.selectExamRecord();
                    }
                }
            },
        },
        data() {
            return {
                loading: false,
                examinationRecordArray: [],
                allInfo: {
                    total: 0
                },
                haveMore: false,
                noMore: false,
                isLoading: true,
                paperName: '',
                matchParent: 'ALL',
                langParent: 'ALL',
                levelParent: 'ALL',
                createTime: null,
                sortFlag: null,
                currentPage: 1,
                pageSize: 10,
            };
        },
        mounted() {
            this.selectExamRecord();
            // let _this = this;
            // window.onscroll = function() {
            // 	//变量scrollTop是滚动条滚动时，距离顶部的距离
            // 	var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            // 	//变量windowHeight是可视区的高度
            // 	var windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
            // 	//变量scrollHeight是滚动条的总高度
            // 	var scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            // 	//滚动条到底部的条件
            // 	if (scrollTop + windowHeight == scrollHeight) {
            // 		//到了这个就可以进行业务逻辑加载后台数据了
            // 		// _this.footerText = '我是有底线的';
            // 		if (_this.allInfo.total > _this.examinationRecordArray.length) {
            // 			if (_this.isLoading) {
            // 				_this.isLoading = false
            // 				_this.haveMore = true
            // 				_this.currentPage = _this.currentPage + 1
            // 				setTimeout(() => {
            // 					console.log("2")
            // 					_this.selectExamRecord()
            // 				}, 1000);
            // 				console.log(_this.examinationRecordArray)
            // 				console.log("到了底部");
            // 			}
            // 		} else {
            // 			_this.haveMore = false
            // 			_this.noMore = true
            // 		}
            // 	}
            // }
        },

        methods: {
            updateSelectPaperName(val) {
                this.paperName = val
            },
            getTestPaperBySelect(data) {
                this.matchParent = data.selectedMatch;
                this.langParent = data.selectedLang;
                this.levelParent = data.selectedLevel;

                this.selectExamRecord();
            },
            selectPaperByMonth2(val) {
                this.sortFlag = val;
                this.selectExamRecord();
            },
            resetData() {
                this.loading = false,
                    this.examinationRecordArray = [],
                    this.allInfo = {
                        total: 0
                    },
                    this.currentPage = 1,
                    this.pageSize = 10,
                    this.haveMore = false,
                    this.noMore = false,
                    this.isLoading = true
            },

            handleSizeChange(val) {
                this.pageSize = val;
                this.currentPage = 1;
                this.selectExamRecord()
            },
            handleCurrentChange(val) {
                this.currentPage = val;
                this.selectExamRecord()
            },
            selectBySubmitDate(val) {
                this.createTime = val;
                this.selectExamRecord();
            },
            resetSelectRecord() {
                this.matchParent = 'ALL';
                this.langParent = 'ALL';
                this.levelParent = 'ALL';
                this.createTime = null;
                this.paperName = "";
                this.selectExamRecord();
            },
            selectExamRecord() {
                this.loading = true;
                let param = {
                    match: this.matchParent,
                    lang: this.langParent,
                    level: this.levelParent,
                    paperName: this.paperName,
                    sortFlag: this.sortFlag,
                    createTime: this.createTime,
                    currentPage: this.currentPage,
                    pageSize: this.pageSize
                };
                examinationRecord(param).then(res => {
                    if (res.code === "0000") {
                        this.allInfo = res.data;
                        this.examinationRecordArray = res.data.list;
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false
                });
            }
        }
    };
</script>

<style lang="scss" scoped>
    .el-divider__text {
        background-color: #f5f5f5;
        color: #808080;
    }

    .exam-record {
        /*height: calc(80vh - 80px);*/
        min-height: 64.5vh;

        .page-breadcrumb {
            margin: 15px 0;
            cursor: pointer;
        }
    }
</style>
